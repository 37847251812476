import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react"
import * as React from "react"
import { PageProps } from "gatsby"
import { FaCheck } from "react-icons/fa"

import Layout from "../components/layout"
import HomesiteEvaluationForm from "../components/homesiteEvaluationForm"
import { UniformBoxProps } from "../components/utils"
import BlockHeader from "../components/blockHeader"
import SEO from "../components/CoreUI/SEO"

const HERO_VIDEO_URL = `https://sundoghomes.s3.amazonaws.com/videos/homesite-hero-1.mp4`

function UniformBox(props: UniformBoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" overflowY="auto" w="800px" {...props} />
}

function HomesiteEvaluations({ location }: PageProps): JSX.Element {
  const [videoWidth, setVideoWidth] = React.useState<number>(500)

  React.useEffect(() => {
    const declareVideoWidth = () => {
      if (typeof window !== `undefined`) {
        setVideoWidth(window.innerWidth)
      }
    }
    declareVideoWidth()
  }, [])

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Know where you want to build your home, but not sure if the land is usable? We’re happy to evaluate your land and give you our expert opinion.",
          image: `${location.origin}/sundog_laughing_trout.png`,
          imageAlt: `An aerial view of Bear Lake`,
        }}
        title="Homesite Evaluations, Before You Build | Sundog Homes"
        url={location.href}
      />
      <Flex
        align="center"
        bg="gray.100"
        h="50vh"
        justify="center"
        maxW="1200px"
        mx="auto"
        w={{ base: `100vw`, xl: `1200px` }}
      >
        <video
          autoPlay
          loop
          muted
          preload="auto"
          style={{
            height: `100%`,
            objectFit: `cover`,
            width: `100%`,
          }}
          width={videoWidth}
        >
          <source src={HERO_VIDEO_URL} type="video/mp4" />
        </video>
      </Flex>
      <UniformBox
        bg="white"
        mt={{ lg: -12, xl: -36 }}
        pos="relative"
        pt={12}
        roundedTop={{ lg: `xl` }}
        textAlign="center"
        zIndex={2}
      >
        <BlockHeader
          bg={[`white`, , `red.500`]}
          color={[`gray.600`, , `white`]}
          mb={4}
          mt={-5}
          maxW="100%"
          mx="auto"
          w="350px"
        >
          Energy Star & Certified Green Homesite Evaluations
        </BlockHeader>
        <Text
          className="decor"
          fontSize="5xl"
          fontWeight="extrabold"
          lineHeight="shorter"
          mt={2}
          px={{ lg: 4, xl: 8 }}
        >
          Before you build, evaluate.
        </Text>
        <Divider
          borderColor="red.500"
          borderBottomWidth="2px"
          maxW="75vw"
          mx="auto"
          pb={4}
          pt={2}
          px={{ lg: 4, xl: 8 }}
          w="300px"
        />
      </UniformBox>
      <UniformBox pt={8} px={{ lg: 4, xl: 8 }}>
        <Text fontSize="lg">
          The diverse, natural landscape of Western North Carolina presents
          unique challenges for homebuilders. Some of these challenges, like
          steep grades and ravines, are clearly seen. Others, however, require a
          highly trained eye and a specialized set of tools to be uncovered. The
          bottom line for landowners is simple: a solid understanding of a
          property goes a long way towards preventing floor plan conflicts,
          construction delays, and unexpected costs down the road.
          <br />
          <br />
          That’s why Sundog Homes is offering no-obligation homesite
          evaluations for both current and prospective landowners that plan to
          build within the next 12 months. Our Energy Star and Certified Green
          builder will spend up to 2 hours inspecting the land of your choosing,
          and will then help you to determine the floor plan and home placement
          that best suits both your property and your budget.
        </Text>
        <Alert bg="blue.50" mt={8} rounded="md" status="info">
          <AlertIcon color="blue.300" />
          <Box>
            <strong>Note:</strong> To make the most of time spent on-site, we
            strongly encourage meeting prior to the evaluation to discuss your
            project's goals and requirements. This additional context allows us
            to provide more insightful analysis.
          </Box>
        </Alert>
      </UniformBox>
      <UniformBox py={12}>
        <Flex
          align="start"
          flexDir={{ base: `column`, md: `row` }}
          justify="space-between"
        >
          <Flex align="start" flexDir="column" w={{ base: `100%`, md: `48%` }}>
            <Box bg="gray.50" px={6} py={6} rounded="lg" w="100%">
              <Flex>
                <Text fontSize="xl" fontWeight="bold" pb={6}>
                  Do you qualify for a homesite evaluation?
                </Text>
              </Flex>
              <VStack align="start" spacing={2} w="100%">
                <Flex>
                  <Icon as={FaCheck} color="red.500" mr={2} mt={1} />
                  <Text>You’ve identified land to purchase in Western NC</Text>
                </Flex>
                <Flex align="center" justify="center" w="100%">
                  <Box bg="gray.300" h="2px" mr={2} rounded="full" w="48px" />
                  <Text color="gray.700" fontStyle="italic" fontWeight="medium">
                    OR
                  </Text>
                  <Box bg="gray.300" h="2px" ml={2} rounded="full" w="48px" />
                </Flex>
                <Flex>
                  <Icon as={FaCheck} color="red.500" mr={2} mt={1} />
                  <Text>You already own land in Western NC</Text>
                </Flex>
                <Flex align="center" justify="center" w="100%">
                  <Box bg="gray.300" h="2px" mr={2} rounded="full" w="48px" />
                  <Text color="gray.700" fontStyle="italic" fontWeight="medium">
                    AND
                  </Text>
                  <Box bg="gray.300" h="2px" ml={2} rounded="full" w="48px" />
                </Flex>
                <Flex>
                  <Icon as={FaCheck} color="red.500" mr={2} mt={1} />
                  <Text>You plan to build within the next 12 months</Text>
                </Flex>
              </VStack>
            </Box>
            <Flex align="center" flexDir="row" justify="space-evenly" w="100%">
              <Image
                alt="The Energy Star Partner logo"
                h={[`75px`, `90px`]}
                mt={6}
                src="/energy-star-partner.svg"
              />
              <Image
                alt="The Green Built Alliance logo"
                h={[`65px`, `80px`]}
                mt={6}
                src="/green-built-alliance-partner.svg"
              />
            </Flex>
          </Flex>
          <Box
            border="1px"
            borderColor="gray.200"
            mt={{ base: 6, md: 0 }}
            px={6}
            pb={12}
            pt={6}
            rounded="lg"
            w={{ base: `100%`, md: `48%` }}
          >
            <Heading as="h4" fontSize="3xl" pb={6}>
              Request Your Homesite Evaluation
            </Heading>
            <HomesiteEvaluationForm
              buttonId="free-homesite-evaluation-btn"
              context={[]}
              includeTextArea={true}
            />
          </Box>
        </Flex>
      </UniformBox>
    </Layout>
  )
}

export default HomesiteEvaluations
